<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    @close="colseDialog"
    @submit="submit"
    width="45%"
  >
    <div class="editTitle">基本信息</div>

    <div class="edit-main">
      <el-form
        :model="editForm"
        label-width="130px"
        :rules="rules"
        ref="editVersionForm"
      >
        <el-row >
          <!-- <el-col :span="12">
            <el-form-item label="所属项目：" prop="projId">
              <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.projId"
                placeholder="请选择所属项目"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="现场编号：" prop="actNo">
              <el-input
                v-model="editForm.actNo"
                size="small"
                placeholder="请输入现场编号"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12">
            <el-form-item label="机械类型：" prop="equipType">
              <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.equipType"
                placeholder="请选择机械类型"
              >
                <el-option
                  v-for="item in equipType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="监控主机IMEI：" prop="deviceImei">
              <el-input
                v-model="editForm.deviceImei"
                size="small"
                placeholder="请输入监控主机IMEI"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="起重机设备编号：" prop="deviceNo">
              <el-input
                v-model="editForm.deviceNo"
                size="small"
                placeholder="请输入起重机设备编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="抓拍日期：" prop="snapTime">
              <el-date-picker
                class="date"
                v-model="editForm.snapTime"
                type="datetime"
                placeholder="选择抓拍日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="抓拍理由：" prop="snapReason">
              <el-input
                class="date"
                v-model="editForm.snapReason"
                type="textarea"
                :rows="2"
                placeholder="请输入抓拍理由"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="抓拍图片：" prop="picUrl">
              <el-upload
                class="avatar-uploader"
                :action="`${uploadUrl}File/upload`"
                :show-file-list="false"
                :on-change="uploadChange"
                :data="{ type: 1 }"
                :on-success="uploadSuccess"
              >
                <img
                  v-if="editForm.picUrl"
                  :src="editForm.picUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
import { equipType, useState } from "../../config/dataStatus";
import monitor from "../../api/modules/monitor";

export default {
  name: "EditVersion",
  components: { CustomDialog },
  props: ["visible", "title", "item"],
  data() {
    return {
      editForm: {
        actNo:"",
        deviceImei:'',
        snapTime:'',
        snapReason:'',
        picUrl:''

      },
      rules: {
        deviceImei: [
          { required: true, message: "请输入监控主机IMEI", trigger: "blur" },
        ],
        actNo: [{ required: true, message: "请输入现场编号", trigger: "blur" }],
        projId: [{ required: true, message: "请选择项目", trigger: "change" }],
        equipModel: [
          { required: true, message: "请输入规格型号", trigger: "blur" },
        ],
        busiId: [
          { required: true, message: "请选择所属商户", trigger: "change" },
        ],
        equipType: [
          { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        snapTime: [
          { required: true, message: "请选择抓拍日期", trigger: "change" },
        ],
        useState: [
          { required: true, message: "请选择使用状态", trigger: "change" },
        ],
      },
      uploadUrl: config.uploadUrl,
      // equipType: [],
      equipType: equipType,
      useStateList: useState,
      projectList: [],
      businessList: [],
    };
  },
  watch: {
    // item(val) {
    //   console.log("val", val);

    //   if (val.equipId) {
    //     this.editForm = val;
    //   } else {
    //     this.editForm = {
    //       equipNo: "",
    //       actNo: "",
    //       projId: "",
    //       busiId: "",
    //       equipType: "",
    //       equipModel: "",
    //       equipDate: "",
    //       useState: "",
    //       annexUrlList: [],
    //     };
    //   }
    // },
  },
  mounted() {
  
  },
  methods: {
    colseDialog() {
      this.$emit("close");
    },

    submit() {
      this.$refs.editVersionForm.validate((valid) => {
        if (valid) {
            monitor.addSnap(this.editForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.$emit("done");
              this.resetForm("editVersionForm");
            });
        }
      });
    },
    uploadChange(file) {
      console.log(file,'file');
      // common.fileUpload()
    },
    uploadSuccess(val) {
      console.log(val,'val');
      if (val.code == 200) {
        this.editForm.picUrl = val.message;
      }
    },
    delImg(index) {
      this.editForm.annexUrlList.splice(index, 1);
      this.$forceUpdate();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
</style>
