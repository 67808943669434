<template>
  <div class="pageMain">
    <div class="flex">
      <!-- 组织项目树 -->

      <OriginTree class="tree" :filterText="filterText" @checked="init">
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
            info="项目信息"
            :iconShow="false"
          >
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="" class="formItem">
                <el-input
                  v-model="filterText"
                  size="small"
                  placeholder="项目"
                ></el-input>
              </el-form-item>
            </el-form>
          </SearchLeft>
        </template>
      </OriginTree>

      <!--右边列表  -->
      <div class="form">
        <SearchHead @search="search" @reset="reset">
          <el-form :inline="true" :model="searchForm">
            <!-- <el-form-item label="选择设备">
              <el-select
                v-model="searchForm.deviceType"
                size="small"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in equipTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="现场编号">
              <el-input
                v-model="searchForm.actNo"
                size="small"
                placeholder="现场编号"
              ></el-input>
            </el-form-item>

            <el-form-item label="主机IMEI">
              <el-input
                v-model="searchForm.deviceImei"
                size="small"
                placeholder="主机IMEI"
              ></el-input>
            </el-form-item>
          </el-form>
        </SearchHead>
        <CustomTables
          :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
        
          :total="total"
          title="抓拍信息"
        >
          <template slot="btns">
            <el-button
              type="success"
              size="small"
              @click="addFrom"
              icon="el-icon-plus"
              class="btn"
              >抓拍</el-button
            >
          </template>

          <el-table-column type="selection" align="center" width="55">
          </el-table-column>
          <el-table-column prop="projName" label="项目名称" align="center">
          </el-table-column>
          <el-table-column prop="actNo" label="现场编号" align="center">
          </el-table-column>
          <el-table-column prop="deviceType" label="机械类型" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.deviceType == 0">塔吊</span>
              <span v-if="scope.row.deviceType == 1">升降机</span>
              <span v-if="scope.row.deviceType == 2">龙门吊</span>
              <span v-if="scope.row.deviceType == 3">架桥机</span>
              <span v-if="scope.row.deviceType == 4">爬模</span>
              <span v-if="scope.row.deviceType == 5">挂篮</span>
            </template>
          </el-table-column>
          <el-table-column prop="deviceNo" label="起重设备编号" align="center">
          </el-table-column>
          <el-table-column
            prop="deviceImei"
            label="监控主机IMEI"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="snapTime" label="抓拍日期" align="center">
          </el-table-column>
          <el-table-column prop="snapReason" label="抓拍理由" align="center">
          </el-table-column>

          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span @click="deleteItem(scope.row)" class="detail delete">
                <i class="el-icon-delete"></i>
              </span>
            </template>
          </el-table-column>
        </CustomTables>
        <candidCameraEdit
          :visible.sync="showDialog"
          @close="colseDialog"
          :title="title"
          @done="doneDialog"
          :item="item"
        ></candidCameraEdit>
      </div>
    </div>
  </div>
</template>

<script>
import OriginTree from "../../../components/common/OriginTree.vue";
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import SearchLeft from "../../../components/common/SearchLeft.vue";
import monitor from "../../../api/modules/monitor";
import candidCameraEdit from "../../../components/monitor/candidCameraEdit.vue";
import {equipType,useState} from "../../../config/dataStatus"
export default {
  components: {
    OriginTree,
    SearchHead,
    CustomTables,
    SearchLeft,
    candidCameraEdit,
  },
  name: "statistics",
  data() {
    return {
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],
      options: [],
      filterText: "",
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      total: 0,
      show: true,
      title: "",
      item: {},
      showDialog: false,
      equipTypeList:equipType
    };
  },
  mounted() {},
  methods: {
    addFrom() {
      this.showDialog = true;
      this.title = "抓拍";
    },
    init(val) {
      this.searchForm.orgaId = val.orgaId;
      this.orgaId=val.orgaId;
      this.searchForm.currPage = 1;
      this.getList();
    },
    getList() {
      monitor.getSnapListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
        orgaId:this.orgaId
      };
      this.getList();
    },
    tableExport() {
      // 导出
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    detailItem() {
      //详情
    },
    deleteItem(item) {
      console.log(item, 'item');
      this.$confirm(`此操作将永久删除设备, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //{staffId:[item.staffId]}
        //  return
        monitor.delSnap(item.snapId).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      })
    },
    doneDialog() {
      this.showDialog = false;
      this.getList();
    },
    colseDialog() {
      this.showDialog = false;
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
  },
};
</script>

<style scoped lang="less">
.flex {
  display: flex;
  height: 100%;
  
  // justify-content: space-between;
}
.tree {
  width: 20%;
  height: 96%;
  
}
.form {
  width: 78%;
  margin-left: 2%;
}
.formItem {
  margin-bottom: 0px;
}
.btn {
  background-color: #008f4d;
  // color: #008f4d;
}

.detail {
  color: #008f4d;
}
</style>
